import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FingoDivider } from '@fingo/lib/components/dividers';
import { PaperHeader } from '@fingo/lib/components/headers';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import EditingGrid from '@fingo/lib/components/dataGrids/EditingDataGrid';
import { UPDATE_USER_INFO } from '@fingo/lib/graphql';
import { useBooleanState, useGetUser } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';

const MyInfo = () => {
  const userObject = useGetUser();
  const [attributeChanges, setAttributeChanges] = useState({});
  const [snackbarOpen, toggleSnackbar] = useBooleanState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [updateUserInfo, { error, loading }] = useMutation(UPDATE_USER_INFO, {
    onError: () => {
      setSnackbarMessage(
        'Ha ocurrido un error. Revise que los datos sean correctos e intente nuevamente.',
      );
      toggleSnackbar();
    },
    onCompleted: () => {
      setAttributeChanges({});
      setSnackbarMessage('Sus cambios fueron guardados exitosamente.');
      toggleSnackbar();
    },
  });
  // eslint-disable-next-line max-len
  const settingAttribute = (attribute) => (newValue) => setAttributeChanges((prev) => ({ ...prev, [attribute]: newValue }));
  const editingGrids = [
    {
      title: 'Empresa',
      getAttribute: (user) => user.selectedCompany?.name,
    },
    {
      title: 'Nombre',
      getAttribute: (user) => user.firstName,
      onChangeInput: settingAttribute('firstName'),
    },
    {
      title: 'Apellido',
      getAttribute: (user) => user.lastName,
      onChangeInput: settingAttribute('lastName'),
    },
    {
      title: 'Teléfono de contacto',
      getAttribute: (user) => user.phoneNumber,
      onChangeInput: settingAttribute('phoneNumber'),
    },
    {
      title: 'Correo de contacto',
      getAttribute: (user) => user.mailProvided,
      onChangeInput: settingAttribute('mailProvided'),
    },
    {
      title: 'Rut representante legal',
      getAttribute: (user) => user.completeRut,
      onChangeInput: settingAttribute('completeRut'),
      isRut: true,
      errorMsg: error && error.message,
    },
  ];
  return (
    <>
      <Stack spacing={4} sx={{ mt: 0 }}>
        <PaperHeader viewTitle="Mi información" />
        <FingoDivider />
        <Typography paragraph gutterBottom>
          Esta es tu información y la de tu empresa que serán necesarias para operar con nosotros
        </Typography>
        <Grid container rowSpacing={4}>
          {editingGrids.map((editing) => (
            <EditingGrid {...editing} user={userObject} key={editing.title} />
          ))}
        </Grid>
        <LoadingButton
          disabled={!Object.keys(attributeChanges).length}
          variant="contained"
          color="primary"
          size="small"
          loading={loading}
          onClick={() => updateUserInfo({ variables: attributeChanges })}
          sx={{
            alignSelf: { xs: 'inherit', sm: 'end' },
            maxWidth: { xs: 'inherit', sm: 'fit-content' },
          }}
        >
          Guardar
        </LoadingButton>
      </Stack>
      <FingoSnackBar
        open={snackbarOpen}
        setOpen={toggleSnackbar}
        message={snackbarMessage}
        severity="error"
      />
    </>
  );
};

export default MyInfo;
