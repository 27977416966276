import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArticleIcon from '@mui/icons-material/Article';
import { Box } from '@mui/material';
import PricingCampaignCompaniesDialog from '../dialogs/PricingCampaignCompaniesDialog';

const PricingCampaignCompanies = ({ pricingCampaignId }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          height: '100%',
        }}
        onClick={() => setOpen(true)}
      >
        <ArticleIcon color="primary" />
      </Box>
      {open && (
        <PricingCampaignCompaniesDialog
          pricingCampaignId={pricingCampaignId}
          open={open}
          handleClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

PricingCampaignCompanies.propTypes = {
  pricingCampaignId: PropTypes.string.isRequired,
};

export default PricingCampaignCompanies;
