import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UploadDropZoneDialog } from '@fingo/lib/components/dialogs';
import { useSnackBars } from '@fingo/lib/hooks';
import { Button } from '@mui/material';
import { UPLOAD_PRICING_BUSINESS_RATES } from '../../graphql/pricing-engine/mutations';

const PricingBusinessRatesActions = () => {
  const [openUploadExcelDialog, setOpenUploadExcelDialog] = useState(false);
  const [files, setFiles] = useState([{ name: '' }]);
  const { addAlert } = useSnackBars();

  const [uploadPricingBusinessRates, { loading }] = useMutation(UPLOAD_PRICING_BUSINESS_RATES, {
    variables: { file: files[0] },
    onCompleted: () => {
      setFiles([{ name: '' }]);
      setOpenUploadExcelDialog(false);
      addAlert({
        id: 'new-pricing-business-rates',
        message: 'Se actualizaron las reglas',
      });
    },
    onError: () => addAlert({ id: 'upload-pricing-business-error', message: 'Ha ocurrido un error' }),
  });

  return (
    <>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => setOpenUploadExcelDialog(true)}
      >
        Actualizar Tasas
      </Button>
      <UploadDropZoneDialog
        open={openUploadExcelDialog}
        setOpen={setOpenUploadExcelDialog}
        files={files}
        setFiles={setFiles}
        message="Carga el excel con las tasas (Formato XLSX - máximo 10Mb)"
        onCompleted={uploadPricingBusinessRates}
        loading={loading}
      />
    </>
  );
};

export default PricingBusinessRatesActions;
