import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PricingBusinessRatesActions from './PricingBusinessRatesActions';
import { PRICING_BUSINESS_RATES } from '../../graphql/pricing-engine/queries';
import { PRICING_BUSINESS_RATE_COLUMNS } from '../../constants/pricing-engine';

const PricingBusinessRates = () => (
  <FingoMainView
    id="pricing-business-rates"
    query={PRICING_BUSINESS_RATES}
    slots={{
      header: PaperHeader,
      actions: PricingBusinessRatesActions,
      table: DataGrid,
    }}
    slotProps={{
      header: {
        viewTitle: 'Tasas',
      },
      table: {
        columns: PRICING_BUSINESS_RATE_COLUMNS,
        disableSelectionOnClick: true,
        density: 'compact',
        noRowsMessage: () => <Typography>No hay reglas.</Typography>,
        initialOrderBy: 'minimumAmount',
        paginationMode: 'server',
        rowsPerPageOptions: [15, 25, 50, 100],
      },
    }}
  />
);

export default PricingBusinessRates;
