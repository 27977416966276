/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useSnackBars } from '@fingo/lib/hooks';
import CreatePricingCampaignDialog from './CreatePricingCampaignDialog';
import { DEACTIVATE_PRICING_CAMPAIGNS, PROCESS_PRICING_CAMPAIGNS } from '../../graphql/pricing-campaigns/mutations';

const PricingCampaignActions = ({ selectedPricingCampaignIds }) => {
  const { addAlert } = useSnackBars();

  const [processPricingCampaigns, { loading: processPricingCampaignsLoading }] = useMutation(PROCESS_PRICING_CAMPAIGNS, {
    variables: {
      pricingCampaignIds: selectedPricingCampaignIds,
    },
    onCompleted: () => {
      addAlert({
        id: 'process-pricing-campaigns-success',
        message: 'Se están procesando las campañas seleccionadas',
      });
    },
    onError: () => {
      addAlert({
        id: 'process-pricing-campaigns-error',
        message: 'Ha ocurrido un error',
      });
    },
  });

  const [
    deactivatePricingCampaigns,
    { loading: deactivatePricingCampaignsLoading },
  ] = useMutation(DEACTIVATE_PRICING_CAMPAIGNS, {
    variables: {
      pricingCampaignIds: selectedPricingCampaignIds,
    },
    onCompleted: () => {
      addAlert({
        id: 'deactivate-pricing-campaigns-success',
        message: 'Se desactivaron las campañas seleccionadas',
      });
    },
    onError: () => {
      addAlert({
        id: 'deactivate-pricing-campaigns-error',
        message: 'Ha ocurrido un error',
      });
    },
  });
  const [openCreatePricingCampaignDialog, setOpenCreatePricingCampaignDialog] = useState(false);

  return (
    <>
      <LoadingButton
        color="primary"
        size="small"
        variant="contained"
        onClick={processPricingCampaigns}
        loading={processPricingCampaignsLoading}
        disabled={
          processPricingCampaignsLoading || !selectedPricingCampaignIds.length
        }
      >
        Activar campañas
      </LoadingButton>
      <LoadingButton
        color="primary"
        size="small"
        variant="contained"
        onClick={deactivatePricingCampaigns}
        loading={deactivatePricingCampaignsLoading}
        disabled={
          deactivatePricingCampaignsLoading
          || !selectedPricingCampaignIds.length
        }
      >
        Desactivar campañas
      </LoadingButton>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => setOpenCreatePricingCampaignDialog(true)}
      >
        Crear campaña
      </Button>
      {openCreatePricingCampaignDialog && (
        <CreatePricingCampaignDialog
          open={openCreatePricingCampaignDialog}
          handleClose={() => setOpenCreatePricingCampaignDialog(false)}
        />
      )}
    </>
  );
};

PricingCampaignActions.propTypes = {
  selectedPricingCampaignIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCampaignActions;
